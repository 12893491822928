import { IframeHTMLAttributes } from 'react';

export interface IframeProps extends IframeHTMLAttributes<HTMLIFrameElement> {
    ariaLabel: string;
    height?: number;
    mobileHeight?: number;
    src?: string;
    type?: string;
    url?: string;
    width?: string;
    border?: string;
}

const Iframe = ({ ...props }: IframeProps) => (
    <iframe width="100%" {...props} />
);

export default Iframe;
